import React from 'react'
import Particles from 'react-particles-js'
import Typical from 'react-typical'
import _ from 'lodash'
import { useSpring, animated } from 'react-spring'
import { useGesture } from 'react-with-gesture'
import KrisZernikel from './kris-zernikel.jpeg'
import '@fortawesome/fontawesome-free/css/all.css'
import Resume from './Kris-Zernikel-Resume-2019-04-19.pdf'
import './App.css';

function Pull({ children }) {
  const [{ xy }, set] = useSpring(() => ({ xy: [0, 0] }))
  const bind = useGesture(({ down, delta, velocity }) => {
    velocity = _.clamp(velocity, 1, 8)
    set({ xy: down ? delta : [0, 0], config: { mass: velocity, tension: 500 * velocity, friction: 50 } })
  })
  return <animated.div className="kz__pull" {...bind()} style={{ transform: xy.interpolate((x, y) => `translate3d(${x}px,${y}px,0)`) }}>{children}</animated.div>
}

function App() {

  return (
    <div className="App">
      <Particles
        className="kz__canvas"
        width="100vw"
        height="100vh"
        params={{
          "particles": {
            "number": {
              "value": 50
            },
            "size": {
              "value": 3
            }
          },
          "interactivity": {
            "events": {
              "onhover": {
                "enable": true,
                "mode": "repulse"
              }
            }
          }
        }}
        />
      <Pull>
        <img draggable="false" className="kz__img" src={KrisZernikel} alt="Kris Zernikel" />
      </Pull>
      <Typical
        className="kz__prompt"
        steps={['I\'m a full stack engineer', 1000, 'I\'m a learner', 1000, 'I\'m a future thinker', 1000, 'I\'m a problem solver', 1000]}
        loop={Infinity}
        wrapper="p"
      />
      <div className="kz__icons">
        <a href="https://www.linkedin.com/in/kriszernikel" target="_blank" rel="noopener noreferrer"><i title="LinkedIn Profile" className="fab fa-linkedin fa-lg"></i></a>
        <a href={Resume} download><i title="Resume" className="far fa-file fa-lg" /></a>
        <a href="https://github.com/KrisZernikel" target="_blank" rel="noopener noreferrer"><i title="GitHub Profile" className="fab fa-github-square fa-lg"></i></a>
      </div>
    </div>
  );
}

export default App;
